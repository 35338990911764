<template>
  <div class="flex flex-col">
    <div class="flex-1">
      <div class="text-white bg-dark">
        <div
          class="container flex flex-col pl-5 pr-4 mx-auto md:pr-20 md:flex-row"
        >
          <div class="flex items-center md:w-1/2">
            <div>
              <h1
                class="mt-6 mb-6 font-extrabold leading-tight  md:mb-10 text-mhead md:text-head md:mt-16"
              >
                Search by type of splint
              </h1>
              <p class="mb-6">
                Splints may be static (preventing motion) or dynamic
                (functional; assisting with controlled motion) used to support
                and protect injured bones, ligaments, tendons, and other
                tissues. The use of splints is generally limited to the short
                term
              </p>
            </div>
          </div>

          <div class="mt-0 md:w-1/2 md:mb-0 md:mt-8">
            <img src="../assets/ArmadilloHoldingSplint.gif" />
          </div>
        </div>
        <!-- Call Out -->
        <callout />
      </div>

      <div class="container mx-auto md:flex md:justify-around">
        <splint :s="$parent.cms.splints[0]" />
        <splint :s="$parent.cms.splints[1]" />
      </div>

      <div class="bg-shade">
        <div class="container mx-auto md:flex md:justify-around">
          <splint :s="$parent.cms.splints[2]" />
          <splint :s="$parent.cms.splints[3]" />
        </div>
      </div>

      <div class="container mx-auto md:flex md:justify-around">
        <splint :s="$parent.cms.splints[4]" />
        <splint :s="$parent.cms.splints[5]" />
      </div>

      <div class="bg-shade">
        <div class="container mx-auto md:flex md:justify-around">
          <splint :s="$parent.cms.splints[6]" />
          <splint :s="$parent.cms.splints[7]" />
        </div>
      </div>

      <!-- <div class="bg-shade"> <div class="md:flex md:justify-around "> </div> </div>-->
    </div>
    <footer-md class="px-10 text-gray-700 md:px-0" />
  </div>
</template>

<script>
import Footer from "../components/Footer.vue";
import Splint from "../components/Splint.vue";
import Callout from "../components/Callout.vue";

export default {
  name: "FindSplint",
  components: {
    "footer-md": Footer,
    Splint,
    Callout,
  },
};
</script>

<style >
h2 {
  font-size: 22px;
}
</style>
