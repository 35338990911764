<template>
  <div class="flex flex-col w-full py-8" xstyle="border:1px solid green">
    <div class="w-300 mx-auto">
      <img :src="imgsrc" :alt="s.Splint" />
    </div>
    <div class="text-center mt-5 px-6 condition-desc mx-auto">
      <a href="#" class="text-xl text-primary hover:text-black font-bold" @click.prevent="result">
        <h2>
          <span v-html="s.Splint"></span>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            version="1.1"
            width="12"
            height="12"
            class="inline-block mb-1 ml-1"
          >
            <polygon fill="#f00" points="12,6 0,12 0,0" />
          </svg>
        </h2>
      </a>
      <h3 class="font-bold leading-tight mt-2">Type:</h3>
      <p class="mb-4 font-medium leading-tight" v-html="s.Type"></p>
      <h3 class="font-bold">Description:</h3>
      <p class="mb-2 font-medium leading-tight" v-html="s.Description"></p>
    </div>
  </div>
</template>

<script>
export default {
  props: ["s"],
  computed: {
    imgsrc() {
      return this.s.ani ? require("../assets/" + this.s.ani) : "";
    },
  },
  methods: {
    result() {
      if (this.s.Choice.trim() == "") {
        this.$parent.$parent.alert(
          "Evaluation of the best<br>splints for this type<br>is being conducted.<br> Stay tuned!"
        );
        // window.history.back();
      } else {
        var s = this.$parent.$parent.slug(this.s.Splint);
        window.router.push({
          name: "Results",
          params: { from: "splint", detail: s },
        });
      }
    },
  },
};
</script>

<style>
.condition-desc {
  max-width: 500px;
}
</style>